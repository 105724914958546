import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { catchError, tap, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ApiService } from '../api.service';
import { HTTP } from '@ionic-native/http/ngx';
import { Platform } from '@ionic/angular';

declare var google;

@Pipe({ name: 'distancepipe' })

@Injectable({
  providedIn: 'root'
})

export class DistancePipe implements PipeTransform {
    constructor(public platform: Platform, public api: ApiService, private geolocation: Geolocation, private http: HttpClient, private ionicHttp: HTTP) {
    }

    async transform(value, args ? : string[]) {
        let storeCoords = value.split(", ");
        let lat = parseFloat(storeCoords[0]);
        let lng = parseFloat(storeCoords[1]);

        let distance = await this.getDistance(lat, lng);
        // console.log(distance);
        return distance;
    }

    async getDistance(lat, lng) { 
        let distance: any = '';
        let service = new google.maps.DistanceMatrixService();

        await this.geolocation.getCurrentPosition().then( async (resp) => {
            let originLat = resp.coords.latitude;
            let originLng = resp.coords.longitude;
            let distanceApi: any;
            let getdistance: any = 0;
            let result: any = [];

            if ( originLat && originLng ) {
                distanceApi = this.getDistanceApi(originLat, originLng, lat, lng);
                distanceApi.then( (response) => {
                    result = response;

                    if ( result.code.toUpperCase() == "OK" ) {
                        distance = parseFloat(result.distances[0][0]) / 1000;
                    }
                }, err => {
	            	console.log(err.error);
	            })
            }
        //    await service.getDistanceMatrix({
        //         origins: [ new google.maps.LatLng(originLat, originLng) ],
        //         destinations: [ new google.maps.LatLng(lat, lng) ],
        //         travelMode: google.maps.TravelMode.DRIVING,
        //         unitSystem: google.maps.UnitSystem.METRIC,
        //         drivingOptions: {
        //             departureTime: new Date(Date.now()),
        //             trafficModel: google.maps.TrafficModel['pessimistic']
        //         }
        //     }, async (response, status) => {
        //         if (status == 'OK') {
        //             let origins = response.originAddresses;
    
        //             for (var i = 0; i < origins.length; i++) {
        //                 var results = response.rows[i].elements;
        //                 for (var j = 0; j < results.length; j++) {
        //                     var element = results[j];
        //                     distance = element.distance.text;
        //                 }
        //             }
        //         }
        //     });
        });

        // console.log(distance);
        return distance;
    }

    getDistanceApi(lat1, long1, lat2, long2) {
        let url = "https://api.mapbox.com/directions-matrix/v1/mapbox/driving/"+long1+","+lat1+";"+long2+","+lat2+"?sources=1&annotations=distance,duration&access_token=pk.eyJ1IjoibXlzdG9yZXNob3BwaW5nIiwiYSI6ImNrdWM1NXZ6aDB4YnAydWs2a3B4dDl0azgifQ.Etp_iudZxtNdgNzqfl3JXg";

        if (this.platform.is('ios') && this.platform.is('hybrid')) {
			return new Promise((resolve, reject) => {
	            this.ionicHttp.get(url, {}, {})
				  .then(data => {
	            	resolve(JSON.parse(data.data));
				  })
				  .catch(error => {
				  	//this.presentAlert(JSON.parse(error.error));
				    reject(JSON.parse(error.error));
			  	});
	        });
		} else {
			return new Promise((resolve, reject) => {
	            this.http.get(url).pipe(map((res: any) => res)).subscribe(data => {
	                resolve(data);
	            }, err => {
	            	//this.presentAlert(err.error);
	            	reject(err.error);
	            });
	        });
		}
    }
} 
