import { NgModule } from '@angular/core';
import { KeysPipe } from './pipe';
import { DatePipe } from './datepipe';
import { DistancePipe } from './distancepipe';

@NgModule({
  declarations: [KeysPipe, DatePipe, DistancePipe],
  exports: [KeysPipe, DatePipe, DistancePipe]
})
export class KeysPipeModule { }
